<template>
    <div class="outer-border " :class="[isSelected ? `border-bank-variant-${promise.bankColour} bank-variant-${promise.bankColour}-lighter`: 'hidden-border']">
        <div class="pretty-card" :class="[promise.bankColour ? `border-bank-variant-${promise.bankColour}-light` : 'default-border']">
            <div class="row mb-2">
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Banca</label>
                <div class="font-weight-bolder" :class="`text-bank-variant-${promise.bankColour}`">
                <img :src="promise.logo ? promise.logo : bankLogoDefault" class="bank-logo sm mr-1">
                {{promise.bankName}}
                </div>
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Scadenza</label>
                {{promise.date ? toFormattedDate(promise.date) : null}}
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100">Tipo</label>
                <div class="capitalize">{{promise.type | movement}}</div>
            </div>
            <div class="col-3">
                <label class="pretty-label mb-0 w-100 text-right">Importo</label>
                    <CellContent
                    :value="promise.amount ? parseFloat(promise.amount) : null"
                    class="d-flex justify-content-end  border-0"
                    />
            </div>
            </div>
            <div class="row">
            <div class="col">
                <label class="pretty-label mb-0 w-100">Controparte</label>
                {{promise.counterpart}}
            </div>
            <div class="col">
                <label class="pretty-label mb-0 w-100">Descrizione</label>
                {{promise.description}}
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

import CellContent from '@/views/components/Table/CellContent.vue';
import { toFormattedDate } from '@/helpers/dates.js';
import formatMovementTypeFilter from '@/filters/formatMovementType';

export default {
  props: {
    item: Object,
    isSelected: Boolean,
  },
  components: {
    CellContent,
  },
  filters: {
    movement: formatMovementTypeFilter,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
    }
  },
  computed: {
    promise () {
      return this.item;
    },
  },
  methods: {
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
  }
};
</script>

<style>

</style>
