<template>
  <div class="matching" ref="automaticMatching">
    <div v-if="isLoadingPage || isLoadingPage2">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <h4 class="mb-5">Riconciliazione automatica</h4>
      <div class="container">
        <carousel-3d ref="carousel" :controls-visible="true" :height="360" :width="350" @after-slide-change="updatecurrentSlide">
            <slide v-for="(slide, i) in slides" :index="i" :key="`slide_${i}`" :controls-visible="true">
                <!--template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                    <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.src">
                    {{index}}
                </template-->
                <div class="header" :class="`text-bank-variant-${movements[i].bankColour || 0} bank-variant-${movements[i].bankColour || 0}-light`">
                  {{movements[i].bankName}}
                </div>
                <div class="body">
                  <div class="row mb-3">
                    <div class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <label class="pretty-label-sm m-0">Controparte</label>
                        </div>
                        <div class="col-12 text-nowrap">
                          {{movements[i].counterpart}}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 text-right">
                      <label class="pretty-label-sm m-0">Importo</label>
                      <CellContent
                        :value="movements[i].amount ? parseFloat(movements[i].amount) : null"
                        class="d-flex justify-content-end m-0 p-0 border-0"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4">
                      <div class="row">
                        <div class="col-12">
                          <label class="pretty-label-sm m-0">Data</label>
                        </div>
                        <div class="col-12">
                          {{movements[i].date ? toFormattedDate(movements[i].date) : null}}
                        </div>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12">
                          <label class="pretty-label-sm m-0">Tipo</label>
                        </div>
                        <div class="col-12 text-capitalize">
                          {{movements[i].type | movement}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0">Descrizione</label>
                    </div>
                    <div class="col-12">
                      {{movements[i].description}}
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="pretty-label-sm m-0">Causale</label>
                    </div>
                    <div class="col-12">
                      {{movements[i].description}}
                    </div>
                  </div>
                </div>
            <figure>
            </figure>
          </slide>
        </carousel-3d>

        <div class="row mb-2">
          <div class="col d-flex align-items-center justify-content-center">
            <div class="current-slide">
              <div class="dark-box mr-1">
                {{currentSlideIndex + 1}}
              </div>
              di
              <div class="font-weight-bolder ml-1">
                {{movements.length}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <h5 class="mb-4">Movimenti in attesa</h5>
      <div class="row">
        <div v-if="suggested" class="col-6 p-1">
            <PromiseCard :item="suggested" class="suggested" :isSelected="isSelected(suggested.id)" data-content='matchAffinity'/>
        </div>
        <div v-for="(promise, i) in promises" :key="`card_${i}`" class="col-6 p-1" @click="updateSelected(promise.id)">
            <PromiseCard :item="promise" :isSelected="isSelected(promise.id)"/>
        </div>
      </div>
    </div>
    <!-- popup edit -->
    <Popup ref="popup" class="md">
      <template #fullContent>
        popup
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" />

    <div class="fade" :class="{'opaque': selectedPromises?.length === 0}">
      <custom-button
        class="custom-button"
        :isLoading="loading"
        :label="`Conferma riconciliazion${selectedPromises?.length > 1 ? 'i' : 'e'} (${selectedPromises?.length})`"
        @click.prevent.native="onSubmit"
      />
    </div>

  </div>
</template>

<script>
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import PromiseCard from '@/views/components/PromiseCard.vue';
import Snackbar from '@/views/components/Snackbar.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';

import { toFormattedDate } from '@/helpers/dates.js';
import formatMovementTypeFilter from '@/filters/formatMovementType';

export default {
  components: {
    'custom-button': Button,
    Carousel3d,
    Slide,
    CellContent,
    Loader,
    Popup,
    PromiseCard,
    Snackbar,
  },
  filters: {
    movement: formatMovementTypeFilter,
  },
  computed: {
    suggested () {
      return this.movements[this.currentSlideIndex].hintPromise;
    },
    matchAffinity () {
      return this.movements[this.currentSlideIndex].hintLevel;
    },
  },
  data () {
    return {
      companyId: this.$oauth2.me.companies[0].id || null,
      isLoadingPage: true,
      isLoadingPage2: true,
      loading: false,
      snackbarText: '',

      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      movements: [],
      slides: 10,
      currentSlideIndex: 0,

      promises: [],

      selectedPromises: [],
      selectedMovement: null, // to do computed $refs currentPage
    };
  },
  methods: {
    getMovements () {
      this.$api.getMovements(this.companyId)
        .then((res) => {
          this.movements = res.data.movements;
          this.slides = this.movements.length;
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
      // getMovements (movimenti avvenuti nelle banche) sopra
      // fetchPromisesDetails (movimenti in attesa)
    },
    getPromises () {
      this.$api.fetchPromisesDetails(this.companyId)
        .then((res) => {
          this.promises = res.data.promises;
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isLoadingPage2 = false;
        });
    },
    isSelected (promiseId) {
      return Boolean(this.selectedPromises.find((obj) => obj.id === promiseId));
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    updateSelected (promiseId) {
      if (this.isSelected(promiseId)) {
        // remove from array
        const foundIndex = this.selectedPromises.findIndex((obj) => obj.id === promiseId);
        this.selectedPromises.splice(foundIndex, 1);
      } else {
        // add to array
        const promise = this.promises.find(obj => obj.id === promiseId);
        this.selectedPromises.unshift(promise);
      }
    },
    updatecurrentSlide (i) {
      this.currentSlideIndex = i;
    },
    onSubmit () {
      this.loading = true;
      this.$api.createMatch(this.companyId, this.selectedPromises[0], this.selectedMovement)
        .then((res) => {
          this.getMovements();
          this.getPromises();
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted () {
    this.getMovements();
    this.getPromises();
  },
};
</script>

<style lang="scss">
.matching {
  background: $primary-lighter;
  .carousel-3d-container {
    overflow: visible;
  }
  .carousel-3d-slide {
    background: white;
    color: $primary;
    border-radius: 10px;
    // padding: 25px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    border: none;
    // transition: box-shadow 500ms ease!important;
    &.left-1 {
      transform: translateX(-300px) translateZ(-120px) rotateY(-27deg)!important;
      box-shadow: none;
      filter: blur(0.03rem);
      &::before {
        content: '';
        display: block;
        position: absolute;
        margin: 0;
        background: #EFF0F4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.2;
      }
    }
    &.right-1 {
      transform: translateX(300px) translateZ(-120px) rotateY(27deg)!important;
      box-shadow: none;
      filter: blur(0.03rem);
      &::before {
        content: '';
        display: block;
        position: absolute;
        margin: 0;
        background: #EFF0F4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.2;
      }
    }
    &.left-2 {
      transform: translateX(-540px) translateZ(-340px) rotateY(-50deg)!important;
      box-shadow: none;
      filter: blur(0.05rem);
      &::before {
        content: '';
        display: block;
        position: absolute;
        margin: 0;
        background: #EFF0F4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.5;
      }
    }
    &.right-2 {
      transform: translateX(540px) translateZ(-340px) rotateY(50deg)!important;
      box-shadow: none;
      filter: blur(0.05rem);
      &::before {
        content: '';
        display: block;
        position: absolute;
        margin: 0;
        background: #EFF0F4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0.5;
      }
    }
  }

  .fade {
    position: sticky;
    bottom: 0;
    height: 120px;
    background: linear-gradient(180deg, rgba(239, 240, 244, 0) 0%, #EFF0F4 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: all 0.5 ease-in;
    &.opaque > * {
      opacity: 0!important;
      transition: all 0.5 ease-in;
    }
  }
  .custom-button {
    position: relative;
    margin: auto;
    pointer-events: auto;
      transition: all 0.5 ease-in;
  }

  .prev, .next {
    color: white;
    &:hover {
      color: $primary-mid;
      opacity: 0.8;
    }
  }
  .current-slide {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    opacity: 0.8;
    font-size: 14px;
  }

  .dark-box {
    padding: 1px 7px;
    min-width: 23px;
    text-align: center;
    background: $primary;
    color: white;
    font-weight: 600;
    border-radius: 5px;
  }
  .header {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 8px;
  }
  .body {
    box-sizing: border-box;
    padding: 15px;
    font-size: 15px;
  }

  .pretty-label {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: $body;
  }

  .pretty-card {
    opacity: 1;
    padding: 15px;
    font-size: 14px;
    min-height: 180px;
    border-style: solid;
    border-width: 2px;
    border-radius: 12px!important;
    margin: 0;
    box-shadow: none;
    cursor: pointer;
    &.default-border {
      border-color: $dodger-blue-light;
    }
  }

  .outer-border {
    box-sizing: border-box;
    padding: 6px;
    border-radius: 20px;
    border-style: solid;
    border-width: 3px;
    &.hidden-border {
      border: 3px solid transparent;
    }
    &.hidden-bg {
      background: transparent;
    }
  }
}
</style>
